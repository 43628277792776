import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { fetchWork } from '../../actions';
import styles from './Work.module.scss'; 


class Work extends Component {
    componentDidMount() {
        this.props.fetchWork();
    }


    renderwork() {
        if(this.props.work){
            console.log(this.props.work)
            const { match } = this.props
            const thisID = match.params.id;
            const workIndex = this.props.work.items.findIndex(work => work.fields.slug === thisID);
            const work = this.props.work.items[workIndex];

            const workImageId = work.fields.screenshots[0].sys.id;
            const workImageIndex = this.props.work.includes.Asset.findIndex(work => work.sys.id === workImageId);
            const workFirstImage = this.props.work.includes.Asset[workImageIndex];

            return (
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{work.fields.title} | Daniel Court</title>
                    </Helmet>
                    <section className={styles.introduction}>
                        <div className={styles.inner}>
                            <h1 className={styles.title}>{work.fields.title}</h1>
                            <div >
                                { work.fields.introduction !== undefined &&
                                    <p className={styles.introduction__text}>{work.fields.introduction}</p>
                                }
                                {work.fields.description  !== undefined &&
                                    <p>{work.fields.description}</p>
                                }
                            </div>
                            
                        </div>		
                    </section>
                    <section className={styles.workList}>
                        <div className={styles.inner}>
                            
                                { work.fields.role !== undefined &&
                                    <div className={styles.workListItem}>
                                        <div className={styles.workListItem__title}>Role</div>
                                        <div className={styles.workListItem__value}>{work.fields.role}</div>
                                    </div>
                                }
                                { work.fields.tech !== undefined &&
                                    <div className={styles.workListItem}>
                                        <div className={styles.workListItem__title}>CMS</div>
                                        <div className={styles.workListItem__value}>{work.fields.tech}</div>
                                    </div>
                                }
                                { work.fields.agency !== undefined &&
                                    <div className={styles.workListItem}>
                                        <div className={styles.workListItem__title}>Agency</div>
                                        <div className={styles.workListItem__value}>{work.fields.agency}</div>
                                    </div>
                                }
                                { work.fields.url !== undefined &&
                                    <div className={styles.workListItem}>
                                        <div className={styles.workListItem__title}>URL</div>
                                        <div className={styles.workListItem__value}><a href={`http://${work.fields.url}`} target="_blank">{work.fields.url}</a></div>
                                    </div>
                                }
                        </div>		
                    </section>
                    <section className={styles.screenshots}>
                        <img src={`${workFirstImage.fields.file.url}?w=1600`} alt="{work.fields.title} example of work" />
                    </section>
                    

                    
                    
                    
                </div>
            );
        } else {
            return (
                <div className="loading">Loading</div>
            );
        }
      }

    render(){
        return (
            <div>
                
                {this.renderwork()}
                <Link to="/" className={styles.backLink}>More Work</Link>
            </div>       
        )
    }
}

function mapStateToProps(state) {
    return { work: state.work };
}

export default connect(mapStateToProps, { fetchWork })(Work);
