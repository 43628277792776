import React, { Component } from 'react';
import styles from './Footer.module.scss';

class Footer extends Component {
    render() {
        return (
            <footer className={styles.mainFooter}>
                <h2 className={styles.mainFooter__title}>Contact</h2>
                <p>Please contact me via email <a href="mailto:dwcourt@gmail.com">dwcourt@gmail.com</a> or <a href="https://uk.linkedin.com/in/daniel-court-5a54082b" target="_blank">LinkedIn</a></p>
            </footer>
        )
    }
}

export default Footer;