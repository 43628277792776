// rendering layer/view control (react router)
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';

import Header from './Header/';
import Footer from './Footer/';
import Home from './Home/';
import Work from './Work/';
import ScrollToTop from './ScrollTop'



class App extends Component {
    componentDidMount(){
        this.props.fetchWork();
    }

    render(){
        return (
            <BrowserRouter>   
                <Header />
                <ScrollToTop>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/work/:id" component={Work} />
                </Switch>
                </ScrollToTop>
                <Footer />
            </BrowserRouter>
        );
    }
}

export default connect(null, actions)(App);