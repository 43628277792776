import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

class Header extends Component {
    render() {
         return (
            <header className={styles.mainHeader}>
                <Link to="/" className={styles.logo}>Daniel Court - Front End Developer</Link>
            </header>
        )
    }
}

export default Header;