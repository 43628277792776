// data layer control (Redux)
import './components/assets/sass/main.scss';
import React from 'react';
import ReactDom from 'react-dom';
import { Provider }  from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import App from './components/App';
import reducers from './reducers';

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));


const rootElement = document.querySelector("#root");

if (rootElement.hasChildNodes()) {
    ReactDom.hydrate(<Provider store={store}><App /></Provider>, rootElement);
} else {
    ReactDom.render(<Provider store={store}><App /></Provider>, rootElement);
}