import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchWork } from '../../actions';
import {Helmet} from "react-helmet";
import styles from './Home.module.scss'; 

class Home extends Component {
    componentDidMount() {
        this.props.fetchWork();
    }

    renderWork() {
        if(this.props.work){
            console.log(this.props)

            return this.props.work.items.map((work, index) => {
                const workImageId = work.fields.screenshots[0].sys.id;
                const workImageIndex = this.props.work.includes.Asset.findIndex(work => work.sys.id === workImageId);
                const workFirstImage = this.props.work.includes.Asset[workImageIndex];

                return (

                     <Link  className={styles.work__item} to={`/work/${work.fields.slug}`} key={index}>
                        <img src={`${workFirstImage.fields.file.url}?w=1200&q=45`} alt="" />
                        <div className={styles.work__title}>{work.fields.title}</div>
                    </Link>
                );
            });
            
        } else {
            return (
                <div className="loading">Loading</div>
            );
        }
       
      }

    render(){
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Daniel Court - Front End Developer</title>
                </Helmet>

                <section className={styles.welcome}>
                    <div className={styles.inner}>
                        <p className={styles.welcome__intro}>Hi, I'm Daniel Court, a Senior Front End Developer based in London.</p>
                        <p>My skills and experience include working with HTML5, CSS3, Less, Sass, BEM, jQuery, JavaScript, React, Redux, ES6, Grunt, Webpack, Git, Magento, Wordpress, Craft and Drupal. I also lead a team of Front End Developers and have experience of working with, and managing, freelancer developers as well as offshore developers.</p>
                    </div>		
                </section>
                <section className={styles.work}>
                 {this.renderWork()}
                </section>

                
                
                
            </div>       
        )
    }
}

function mapStateToProps(state) {
    return { work: state.work };
}

export default connect(mapStateToProps, { fetchWork })(Home);
