import axios from 'axios';
import { FETCH_WORK } from './types';

const API_BASE_URL = 'https://cdn.contentful.com';
const API_SPACE_ID = '9r3b5p9ki308';
const API_KEY = 'RxKrpfYCq2xZwc9Cp8UZcy9RpMaqq8qn79SIk4gS6Tc';

/*recipes
https://cdn.contentful.com/spaces/9r3b5p9ki308/entries?access_token=RxKrpfYCq2xZwc9Cp8UZcy9RpMaqq8qn79SIk4gS6Tc&content_type=work
*/

export const fetchWork = () => async dispatch => {
    const res = await axios.get(`${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_KEY}&content_type=work&order=fields.order`);
    dispatch({ type: FETCH_WORK, payload: res.data });
};
